import React from 'react'
import me from '../assets/me.png'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-scroll'

const Home = () => {
    return (
        <div name="home" className='h-screen w-full bg-gradient-to-b from-black via-black to-gray-800'>

            <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-screen px-4 md:flex-row'>

                <div className='flex flex-col justify-center h-full'>

                    <h2 className='text-4xl sm:text-7xl font-bold text-white'>Full Stack Developer</h2>
                    <p className='py-4 max-w-md text-white'>I'm based in Sacramento, CA who prides himself in constructing both the back-end and front-end of an appliation to generate amazing user experiences.</p>
                    <div>
                        <Link to="portfolio" smooth duration={500} className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-tr from-cyan-500 to-blue-500 cursor-pointer'>
                            Portfolio
                            <span className='group-hover:rotate-90 duration-300'>
                                <MdKeyboardArrowRight size={25} className='ml-1' />
                            </span>
                        </Link>
                    </div>
                </div>
                <div>
                    <img src={me} alt='my profile' className='rounded-3xl mx-auto w-2/3 md:w-full max-w-xs' />
                </div>
            </div>
        </div>
    )
}

export default Home
