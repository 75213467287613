import React from 'react'
import { FaGithub, FaLinkedin, FaGitlab } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'

const Social = () => {

    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/in/jaspreet-sanghaswe/',
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/IamYorye',
        },
        {
            id: 3,
            child: (
                <>
                    GitLab <FaGitlab size={30} />
                </>
            ),
            href: 'https://gitlab.com/IamYorye',
        },
        {
            id: 3,
            child: (
                <>
                    Email <HiOutlineMail size={30} />
                </>
            ),
            href: 'mailto:jaspreetsangha204@yahoo.com',
        },
    ]
    return (
        <div className=' hidden lg:flex flex-col top-[35%] left-0 fixed'>
            <ul>
                {links.map(({ id, child, href, style }) => (
                    <li key={id} className={'flex justify-between items-center w-40 h-14 px-4 ml-[-100px] bg-gray-500 hover:ml-[-10px] hover:rounded-md duration-300 + " " + style'}>
                        <a href={href} className='flex justify-between items-center w-full text-white'
                            target='_blank'
                            rel="noreferrer"
                        >
                            {child}
                        </a>
                    </li>
                ))}

            </ul>
        </div>
    )
}

export default Social
