import React from 'react'
import css from '../assets/css.png'
import django from '../assets/django.png'
import docker from '../assets/docker.png'
import fast from '../assets/fastapi.png'
import git from '../assets/git.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import node from '../assets/nodejs.png'
import postgres from '../assets/Postgresql_elephant.svg.png'
import python from '../assets/python.png'
import reactjs from '../assets/reactjs.png'
import tw from '../assets/tailwind.png'


const Experience = () => {

    const techs = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: python,
            title: 'Python',
            style: 'shadow-violet-500'
        },
        {
            id: 5,
            src: reactjs,
            title: 'React',
            style: 'shadow-teal-400'
        },
        {
            id: 6,
            src: tw,
            title: 'Tailwind',
            style: 'shadow-sky-500'
        },
        {
            id: 7,
            src: node,
            title: 'NodeJS',
            style: 'shadow-lime-500'
        },
        {
            id: 8,
            src: docker,
            title: 'Docker',
            style: 'shadow-cyan-500'
        },
        {
            id: 9,
            src: django,
            title: 'Django',
            style: 'shadow-emerald-500'
        },
        {
            id: 10,
            src: fast,
            title: 'FastAPI',
            style: 'shadow-sky-500'
        },
        {
            id: 11,
            src: git,
            title: 'Git',
            style: 'shadow-red-500'
        },
        {
            id: 12,
            src: postgres,
            title: 'PostgreSQL',
            style: 'shadow-indigo-500'
        },
    ]

    return (
        <div
            name="experience"
            className="bg-gradient-to-b from-gray-800 to-black w-full h-fit"
        >
            <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
                <div>
                    <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
                        Experience
                    </p>
                    <p className="py-6">These are the tech stacks I've used on previous projects.</p>
                </div>

                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
                    {techs.map(({ id, src, title, style }) => (
                        <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                            <img src={src} alt="" className='w-20 mx-auto' />
                            <p className='mt-4'>{title}</p>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default Experience
